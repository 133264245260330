























































































































































































































































































































































































































































.searchBox {
  padding: 0 !important;
  .el-icon-arrow-down:before {
    content: '\e6df' !important;
  }
}
.activityItem {
  background: #ccc;
  padding: 20px;
  margin-left: 176px;
  margin-bottom: 20px;
  border-radius: 10px;
  width: 724px;
  position: relative;
  /deep/ .el-form-item__content .el-select {
    float: left;
  }
  /deep/ .el-form-item__content .el-button {
    float: right;
  }
  /deep/ .el-form-item__content .el-button + .el-button {
    margin-right: 15px;
  }
}
.el-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.common-main-dev {
  padding-bottom: 0.675rem;
  border-bottom: 1px solid #f5f5f6;
}
.common-main-dev .searchboxItem {
  margin-right: 10px;
}
.common-main-dev > div {
  display: flex;
  overflow: hidden;
}
.common-main-dev > div > div > span {
  min-width: 5rem;
  text-align: right;
  font-size: 14px;
  padding: 0 0.5rem;
}
